.dms-table-image-preview > img { max-height: 20px }
.dms-tile-image-preview > img,
.dms-tile-image-preview > .fa { max-width: 100%; height: 125px; }
.dms-tile-image-preview > .fa { font-size: 100px; padding-top: 15px; }

.dms-tile-container { margin-top: 10px; margin-bottom: 10px; }
.dms-tile-element.tile-element-folder { border: 1px solid #f1f1f1; padding: 5px; }
.dms-tile-element.tile-element-document { border:1px solid #f1f1f1; padding: 5px }
.dms-tile-element.tile-element-document .dms-tile-element-footer { padding-top: 3px; overflow: hidden; }

.dms-gallery-container { margin-bottom: 10px; border: 1px solid #f1f1f1 }
.dms-gallery-container .gallery-viewer .gallery-viewer-content { text-align: center; height: 400px; overflow: hidden; background-color: #f4f4f4; }
.dms-gallery-container .gallery-viewer .gallery-viewer-content > img { display: block; height: 100% !important; margin: 0 auto }
.dms-gallery-container .gallery-viewer .gallery-viewer-content .fa { width: 100%; margin: 50px 0; font-size: 300% }
.dms-gallery-container .gallery-viewer .description { overflow: hidden; padding: 5px; background-color: #000; color: #fff; }
.dms-gallery-container .gallery-thumbnails { overflow: hidden }
.dms-gallery-container .gallery-thumbnails .gallery-thumbnail { float: left; margin: 5px 3px; border: 1px solid #fff }
.dms-gallery-container .gallery-thumbnails .gallery-thumbnail.active { border-color: blue }
.dms-gallery-container .gallery-thumbnails .gallery-thumbnail img { height: 25px; max-width: 100% }
.dms-gallery-container .gallery-thumbnails .gallery-thumbnail .fa { height: 22px; width: 48px; text-align: center; }
