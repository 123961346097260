$theme-colors: (
  "primary": #3c8dbc,
  "danger": #ff4136,
//   "secondary": '',
//   "info": '',
//   "default": ''
);

.custom-file:lang(it) .custom-file-label::after {
  content: "Scegli";
}