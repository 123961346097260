@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/v4-shims.scss";
.fa.fa-remove {
    &:before {
        content: fa-content($fa-var-times);
    }
}

.fa.fa-arrows {
    &:before {
        content: fa-content($fa-var-arrows-alt);
    }
}

.fa.fa-dot-circle-o {
    @extend .far;

    &:before {
        content: fa-content($fa-var-dot-circle);
    }
}