td.widget-1 {
    width: 200px;
}
td.widget-2 {
    width: 300px;
}
td.widget-3 {
    width: 100px;
}
td.widget-4 {
    width: 100px;
}
td.widget-5 {
    width: 50px;
    text-align: center;
}
table.dataTable thead > tr > th.flexible,
table.dataTable tbody > tr > td.flexible{
    padding: 0;
    width: auto;
}

#panel-action-list iframe {
    width: 100%;
    min-height: 100%;
    border: none;
}

.alfresco-action a{
    padding: 5px;
    cursor: pointer;
}
#alfresco-upload{
    border:1px solid #111;
    padding: 5px 15px;
    cursor:pointer;
}
.breadcrumb { margin: 10px 0 }
.breadcrumb a{
    cursor:pointer;
}
#table-objects {
    width: 100%;
}
#table-objects .icon{
    width: 25px;
}
#table-objects .size{
    width: 100px;
}
#table-objects .date{
    width: 150px;
}
#table-objects .tools { min-width:30px; }

.cartoaf-grid .table-responsive { padding-bottom:70px; }
.cartoaf-grid th, .cartoaf-grid td { font-size:12px; }
.cartoaf-grid td span { white-space: nowrap }
.sidebar-menu { overflow:initial; }
.sidebar-menu>li>a { padding-top:7px;padding-bottom:7px; }
td.actions { white-space: nowrap; width:80px !important; }
td.selection { width:30px !important; }
form { margin-bottom:20px; }

.cartoaf-grid .cell-with-minimum { min-width:150px; }
.cartoaf-grid .widget-3 p { margin-bottom:0 }
.cartoaf-grid .btn-group-justified { margin-top:5px; }

.content-wrapper .content { position:relative; }

.http-requests-spinner { 
    position: fixed;
    display: inline;
    padding: 5px;
    text-align: center;
    top: 0;
    z-index: 3000;
    left: 50%;
    transform: translateX(-50%);
    background-color: #343a40;
    color: rgba(255, 255, 255, 0.87);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.angucomplete-dropdown { max-height: 200px; overflow: scroll;width:100%; }

.autocomplete-popup {
    overflow: scroll;
    max-height: 250px;
    width: 100%;
    z-index: 200;
}

textarea.form-control { height:140px; }

.main-header .navbar { margin-left:0 }
.main-header .navbar .navbar-brand { text-transform:uppercase }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #1e282c !important;
    border-left: solid 3px #3c8dbc;
    // ~ul {
    //     background-color: #1e282ca6;
    // }
}

.gap-1 {
    gap: 0.25rem;
}

.widget-textarea {
    min-height: 140px;
}

.base-footer {
    background: #fff;
    border-top: 1px solid #dee2e6;
    color: #869099;
    padding: 1rem;
}

.base-header {
    border-bottom: 1px solid #dee2e6;
}

.main-sidenav {
    width: 270px;
    .mat-list-base .mat-list-item {
        color: rgba(255, 255, 255, 0.87);
    }

    .mat-list-base .mat-subheader {
        color: rgba(255, 255, 255, 0.54);
    }

    .mat-list-base .mat-list-item:hover {
        background-color: rgba(0, 0, 0, 0.24);
    }

    .mat-tree-node, .mat-nested-tree-node {
        color: rgba(255, 255, 255, 0.87);
        .btn {
            color: rgba(255, 255, 255, 0.87);
        }
    }
}

.btn-default.active {
    background-color: #e9ecef;
    color: #2b2b2b;
}


.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// Badge

.btn {
    position: relative !important;
    .badge {
        font-size: 10px;
        font-weight: 400;
        position: absolute !important;
        right: -5px;
        top: -5px !important;
        z-index: 1;
    }
}

.form-CartoAF {
    .cartoaf-group-header {
        font-size: 1.2rem;
        font-weight: 500 !important;
    }
    
    .form-group label {
        font-weight: 400 !important;
    }
}