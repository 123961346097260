

@import './assets/scss/custom-theme.scss';

@import './assets/scss/fonts.scss';
@import './assets/scss/application.scss';
@import './assets/scss/dms.scss';
@import "./assets/scss/material-custom.scss";


html {
    @import "~admin-lte";
}


/** Swiper **/

@import '/node_modules/swiper/swiper.scss';
@import './assets/scss/swiper.scss';

@import '~codemirror/lib/codemirror';
@import '~codemirror/theme/idea.css';
@import '~codemirror/addon/fold/foldgutter.css';
@import '~codemirror/addon/lint/lint.css';

@import "~@ng-select/ng-select/themes/default.theme.css";

// bootstrap style toast
// or import a bootstrap 4 alert styled design (SASS ONLY)
@import "~bootstrap/scss/mixins/_border-radius.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import '~ngx-toastr/toastr-bs4-alert';

.toast-container .ngx-toastr {
    padding: 0;
    .toast {
        background-color: rgba(255, 255, 255, 0.85) !important;
    }
}

/**** General ****/

html{
    position:relative; 
    height: 100% !important;
}

html,body{
    margin:0;
    padding:0;
}

body {
    font-family: 'Open Sans', sans-serif;
    color: #333;
    box-sizing: border-box;
    height: 100% !important;
}

.cursor-pointer { cursor: pointer }

#maincontainer { height: 100% !important; }

.content-loading{
    height: 100%;
    display: flex;
    justify-content: center;
    img {
        margin-top: auto;
        margin-bottom: auto;
    }
}

.modal-CartoAF .modal-footer a{
    font-size: 20px;
    cursor: pointer;
}

.form-CartoAF .form-group { min-height:60px; }

.form-CartoAF p.input-group{
    margin:0;
}

.resize-handle{
    float: right;
    padding: 10px;    
}

.box[resizable] .box-footer{   
    cursor:ns-resize;
}
/**** End General ****/


/**** Grid ****/
/*
.cartoaf-grid .table > tbody > tr > th,  
.cartoaf-grid .table > tbody > tr > td {
    padding: 2px;
}
.cartoaf-grid .pagination > li > a, .pagination > li > span { 
    padding: 2px 5px;
}*/


/**** End Grid ****/

/**** Validation ****/

.has-error div > span, .has-error > span {
    color: red;
}

.form-group.is-invalid {
    .ng-invalid.ng-touched {
        border-color: #dc3545;
    }
    label {
        color: #dc3545;
    }
}

.form-control.ng-invalid.ng-touched {
    border-color: #dc3545;
}

/**** End Validation ****/


/***** Pagination *****/
div.pagination {
    line-height: 14px;
    min-height: 30px;
    padding: 5px 10px;
    background: #FF4200;
    color: #FFF;
    font-size: 12px;
    margin:0px;
    width: 100%;
}

div.pagination div.prevnextbtn {
    float: left;
    margin-right: 10px;
}

div.pagination div.prevnextbtn span {
    padding: 0 5px;
    margin: 2px;
    border: 1px solid #FFF;
    cursor: pointer;
    opacity: 1;
    float: left;
}

div.pagination .pages {
    display: inline-flex;
    left: 0;
    position: relative;
}

div.pagination .pagewrapper {
    width: 165px;
    overflow: hidden;
    height: 21px;
    float: left;
    margin: 0 5px;
}

div.pagination div.pages span {
    width: 25px;
    padding: 0 1px;
    text-align: center;
    box-sizing: content-box;
    font-size: 11px;
}

div.pagination div.pages span.currentPage,
div.pagination div.prevnextbtn span.disabled{
    opacity: 0.5;
    cursor: default;
}

div.pagination .pagesize{
    appearance:none;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    background-color: #FF4200;
    border: 1px #FFF solid;
}
/*****End Pagination *****/

.btn-grid-group { margin-bottom:10px; }
th.sorting { cursor:pointer; }

.sidebar-menu li>a>.pull-right-container>.fa { margin-right:10px; }

.cartoaf-justified-grid { margin-bottom: 20px;border: 1px solid #f4f4f4; }
.cartoaf-justified-grid [class^="col-"] {
    border: 1px solid #f4f4f4; padding:0;
}

.cartoaf-justified-grid [class^="col-"] p {
    padding: 5px; margin:0; font-size: 12px;
    font-weight: bold;
}

.treeview a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn-group-wrapper { overflow:hidden; }
.btn-group-wrapper > .btn-group.pull-left { margin-right:10px; }

#alert-boxes {
    overflow: hidden;
    margin-bottom: 10px
}
#alert-boxes .content-box {
	float:left;
	width: 20%;
    min-height: 130px;
    min-width: 180px;
    text-align: center;
}

#alert-boxes .content-box h3 { margin-top: 0 }
#alert-boxes .alert-symbol { background-color: #ccc; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.w-auto {
    width: auto;
}
.h-auto {
    height: auto;
}