.swiper.swiper-tabs {
    width: 100%;
    height: auto;
    position: relative;

    .swiper-slide {
        height: auto;
        width: auto !important;
    }
}

.swiper-mybutton-next,
.swiper-mybutton-prev {
    border-radius: 0 !important;
    border-bottom: none;
    i {
        vertical-align: middle;
    }
}

.swiper-mybutton-prev {
    border-top-left-radius: 5px !important;
}

.swiper-mybutton-next {
    border-top-right-radius: 5px !important;
}

.swiper-mybutton-prev.swiper-button-disabled,
.swiper-mybutton-next.swiper-button-disabled {
    display: none;
}