@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$md-cartoafprimary: (
    50 : #ffffff,
    100 : #c7dfed,
    200 : #9dc7e0,
    300 : #67a8ce,
    400 : #509bc7,
    500 : #3c8dbc,
    600 : #357ca5,
    700 : #2d6a8e,
    800 : #265976,
    900 : #1e475f,
    A100 : #f9fcfe,
    A200 : #9cd4f5,
    A400 : #4fa8dc,
    A700 : #469bcc,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-cartoafaccent: (
    50 : #e0edf5,
    100 : #a6cce3,
    200 : #7bb4d5,
    300 : #4595c4,
    400 : #3885b2,
    500 : #31749b,
    600 : #2a6384,
    700 : #22516c,
    800 : #1b4055,
    900 : #142e3e,
    A100 : #d1ebfa,
    A200 : #74c3f1,
    A400 : #2a96d5,
    A700 : #3184b4,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$catoaf-app-primary: mat.define-palette($md-cartoafprimary);
$catoaf-app-accent:  mat.define-palette($md-cartoafaccent);

// The warn palette is optional (defaults to red).
$catoaf-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$catoaf-app-theme: mat.define-light-theme((
  color: (
    primary: $catoaf-app-primary,
    accent: $catoaf-app-accent,
    warn: $catoaf-app-warn,  
  ),
  density: -1
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($catoaf-app-theme);

.cdk-overlay-container {
    z-index: 1040;
}

.mat-list-item-disabled {
    background-color: #5c5c5c;
}